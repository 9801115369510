body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loginLogo {
  width: 320px;
  height: auto;
  margin: auto;
  padding: 1rem;
  padding-bottom: 3rem;
}

.makeStyles-root * {
  display: flex !important;
  flex-direction: column !important;
}
/* .MuiInputBase-input {
   text-align: center !important; 
} */

.ant-menu-item,
.ant-menu-submenu {
  border-bottom: 1px solid #afb6bc;
}
.ant-menu-item-only-child {
  border: none;
}
.link {
  color: #ababab;
}
.pagination {
  display: flex;
  justify-content: right;
}
.MuiTypography-colorPrimary {
  color: #3f51b5;
}

.btn-success {
	color: #fff;
	background-color: #5cb85c;
	border-color: #4cae4c
}
.btn-primary {
  color: white !important;
  background-color: #ecb445 !important;
  border-color: #ecb445 !important;
}
.btn-warning {
  color: white !important;
  background-color: #001529 !important;
  border-color: #001529 !important;
}
.btn-secondary {
  color: white !important;
  background-color: #754527 !important;
  border-color: #754527 !important;
}
.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.btn-dark {
  color: #fff !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}
.logo {
  width: auto;
  height: 50px;
  padding-left: 1rem;
}
.top {
  padding-left: 0.5rem;
  padding-right: 2rem;
  font-size: 12px;
  padding-top: 0.5rem;
  color: #2f3847;
  visibility: hidden;
}
.top:hover {
  color: #ecb445;
}
.form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}

@media (min-width: 1050px) {
  .top {
    visibility: visible;
  }
}
.navUser {
  display: none;
}
@media (min-width: 740px) {
  .navUser {
    display: block;
  }
}

:root {
  --amplify-primary-color: #2f3847;
  --amplify-primary-tint: #75849d;
  --amplify-primary-shade: #75849d;
}
